
import { Component, Vue } from 'vue-property-decorator';
import { DisplayDutyInfo, VirtualRelation } from '../../models/Product';
import ProductDetailsModule from '../../store/modules/ProductDetailsModule';

@Component({
    name: 'DetailsDuties',
    components: {
    }
})
export default class DetailsDuties extends Vue {

    get relations(): VirtualRelation[] {
        if (ProductDetailsModule.SELECTED_VARIANT)
            return ProductDetailsModule.SELECTED_VARIANT?.virtualrelations;
        else
            return ProductDetailsModule.PRODUCT?.virtualrelations;
    }

    get duties(): DisplayDutyInfo[] {
        if (this.relations?.length > 0)
        {
            return ProductDetailsModule.SELECTED_VIRTUAL_RELATION?.duties ?? [];
        }
        if (ProductDetailsModule.VARIANTS?.length > 0) {
            return ProductDetailsModule.SELECTED_VARIANT?.duties ?? [];
        }
        return ProductDetailsModule.PRODUCT?.duties ?? [];
    }

    private getPrice(duty: DisplayDutyInfo)
    {
        if (duty.showpricesincvat)
            return duty.priceincvat

        return duty.priceexvat;
    }    
}
